<template>
    <b-form @submit.prevent="createLoveMessage">
        <b-row>
            <b-col lg="4" xl="5">
                <multi-select v-model="selectedUser"
                              placeholder="Destinataire"
                              :options="allUsers"
                              :multiple="false"
                              track-by="id"
                              :close-on-select="true"
                              :clear-on-select="true"
                              :preserve-search="false"
                              :custom-label="textUsername"
                              class="mb-2"/>
            </b-col>
            <b-col lg="4" xl="5">
                <b-input v-model="message" max="255" class="mb-2" placeholder="Message optionnel"/>
            </b-col>
            <b-col lg="4" xl="2">
                <b-button type="submit" variant="success">
                    Envoyer
                    <font-awesome-icon icon="heart"/>
                </b-button>
            </b-col>
        </b-row>
    </b-form>
</template>

<script>
    import {textUsername} from '@/util/user';
    import alert          from '@/util/alert';
    import {apiPath}      from '@/util/http';

    const MultiSelect = () => import('@/components/MultiSelect');

    export default {
        name: 'LoveMessageForm',
        components: {MultiSelect},
        data: () => ({
            allUsers: [],
            selectedUser: null,
            message: null
        }),
        methods: {
            textUsername,
            createLoveMessage() {
                if (this.selectedUser) {
                    alert.loading();
                    this.axios.post(apiPath('new_love_message', {to: this.selectedUser.id}),
                        {message: this.message && this.message.trim().length ? this.message : null})
                        .then(() => this.$toaster.success('Cœurs envoyés ♥'))
                        .catch(() => this.$toaster.error('Impossible d\'envoyer des cœurs ☹'))
                        .finally(alert.stopLoading);
                }
            },
            loadData() {
                alert.loading();
                this.axios.post(apiPath('admin_valid_users_quickview'))
                    .then(response => this.allUsers = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des utilisateurs'))
                    .finally(alert.stopLoading);
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>
